<template>
  <div class="index-page">
    <div class="wrap">
      <el-carousel class="banner" height="400px">
        <el-carousel-item v-for="(item,index) in sliders" :key="index">
          <a :href="item.url" target="_blank"><img class="img" :src="item.thumb" /></a>
        </el-carousel-item>
      </el-carousel>
      <div class="notice" v-if="announcement.title">
        <a :href="announcement.url">{{announcement.title}}</a>
      </div>
      <div class="fast-nav">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="item z-flex">
              <div class="item-hd">学习指引</div>
              <div class="item-bd">
                <a class="item-text" target="_blank" :href="url" v-for="(url, name) in link1" v-html="name"></a>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item z-flex">
              <div class="item-hd">后期类别</div>
              <div class="item-bd">
                <a class="item-text" target="_blank" :href="url" v-for="(url, name) in link3" v-html="name"></a>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="item z-flex">
              <div class="item-hd">学习难度</div>
              <div class="item-bd">
                <a class="item-text" target="_blank" :href="url" v-for="(url, name) in link5" v-html="name"></a>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="item z-flex">
              <div class="item-hd">学院介绍</div>
              <div class="item-bd">
                <a class="item-text" target="_blank" :href="url" v-for="(url, name) in link2">{{name}}</a>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item z-flex">
              <div class="item-hd">其他类别</div>
              <div class="item-bd">
                <a class="item-text" target="_blank" :href="url" v-for="(url, name) in link4">{{name}}</a>
              </div>
            </div>
          </el-col>
          <el-col :span="10">
            <div class="item z-flex">
              <div class="item-hd">下载中心</div>
              <div class="item-bd">
                <a class="item-text" target="_blank" :href="url" v-for="(url, name) in link6">{{name}}</a>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="course-list-wrap" v-for="(banner, index) in banners" :key="index">
        <div class="hd z-flex z-flex-between">
          <div class="hd-l z-flex">
            <i class="el-icon-reading"></i>
            <span>{{banner.name}}</span>
          </div>
          <el-button 
            class="hd-more" 
            type="primary" 
            size="mini" 
            plain
            @click="toUrl('/list')"
          >
            更多<i class="el-icon-menu el-icon--right"></i>
          </el-button>
        </div>
        <div class="bd z-flex">
          <courseItem
            v-for="(item,index) in banner.courses"
            :key="index"
            :itemData = item
          >
          </courseItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import courseItem from "../components/courseItem"
export default {
  components: {
    courseItem
  },
  data() {
    return {
      banners:[],
      sliders:[],
      announcement:{
        url: '',
        title: ''
      },
      sortList:[
        {
          title:'专区课程',
          list:[],
        },
        {
          title:'下载中心',
          list:[],
        },
      ],
      link1:[],
      link2:[],
      link3:[],
      link4:[],
      link5:[],
      link6:[]
    };
  },
  mounted() {
    this.$axios.api.edulink({}, (res) => {
      if (res.code == 200) {
        this.link1 = res.data.link1;
        this.link2 = res.data.link2;
        this.link3 = res.data.link3;
        this.link4 = res.data.link4;
        this.link5 = res.data.link5;
        this.link6 = res.data.link6;
      } else {
        console.log(res.msg);
      }
    });
    this.$axios.api.home({}, (res) => {
      if (res.code == 0) {
        this.sliders = res.data.sliders;
        this.announcement = res.data.announcement;
        this.sortList = res.data.sortList;
        this.banners = res.data.banners;
      } else {
        console.log(res.msg);
      }
    });
  },
  methods: {
    toUrl(url){
      this.$router.push({
        path:url,
      })
    },
    toHref(href){
      window.location.href = href;
    },
  },
};
</script>

<style lang="scss" scoped>
 .banner{
   margin:20px 0 0;
   .img{
     width:100%;
     height:100%;
     object-fit:cover;
     cursor: pointer;
   }
   ::v-deep .el-carousel__arrow{
     width: 60px;
     height: 60px;
     font-size: 30px;
   }
 }
 .notice{
   height: 50px;
   line-height: 50px;
   background: #fff9d7;
   padding-left: 20px;
   a{
     color: #de7a0b;
     font-size: 18px;
   }
 }
 .sort{
  margin-top: 30px;
  .sort-item{
    margin: 0 20px 20px 0;
    ::v-deep .sort-item-link{
      &:hover{
        color: $colorMain;
      }
    }
  }
 }
 .fast-nav{
   margin-top: 30px;
   font-size: 14px;
   .item{
     padding:15px 20px;
     background: #fff;
     .item-hd{
       width:80px;
       position: relative;
       &::after{
         content: "|";
         font-family: SimSun;
         font-size: 14px;
         color: #ddd;
         position: absolute;
         right: 0;
         top: 50%;
         transform:translateY(-50%);
       }
     }
     .item-bd{
       width:calc(100% - 80px);
       @extend %textOverflow;
       .item-text{
         margin-left: 15px;
         color: #888;
         &:hover{
           color:$colorMain;
         }
       }
     }
   }
 }
 .course-list-wrap{
   margin-bottom: 30px;
   .hd{
      margin: 40px 0 30px;
      position: relative;
      border-bottom: $border;
      padding-bottom: 15px;
      .hd-l{
        font-size: 25px;
        i{
          font-size: 28px;
          margin-right: 10px;
        }
      }
   }
   .bd{
     .course-item{
       margin: 0 20px 20px 0;
       &:nth-child(4n){
         margin: 0 0 20px 0;
       }
     }
   }
 }
</style>
